import qs from "query-string";
import BaseApiService, { RequestOptions } from "./BaseApiService";
import authService from "store/auth/AuthService";
import type Iam from "types/Iam";
import type ListResponse from "@mapmycustomers/shared/types/viewModel/ListResponse";
import type { SimpleListResponse } from "@mapmycustomers/shared/types/viewModel/ListResponse";
import type Currency from "@mapmycustomers/shared/types/Currency";
import type Organization from "@mapmycustomers/shared/types/Organization";
import type Company from "@mapmycustomers/shared/types/entity/Company";
import type RawFile from "@mapmycustomers/shared/types/File";
import type User from "@mapmycustomers/shared/types/User";
import type { UserRef } from "@mapmycustomers/shared/types/User";
import type {
  Activity,
  AnyEntity,
  AnyEntityId,
  Deal,
  EntitiesSupportingFiles,
  EntitiesSupportingHistory,
  EntitySupportingCustomFields,
  EntityTypesSupportedByIntegrations,
  EntityTypesSupportingFieldCustomization,
  EntityTypeSupportingCustomFields,
  EntityTypeSupportingDataMerging,
  EntityTypeSupportingFiles,
  EntityTypeSupportingGroups,
  EntityTypeSupportingHistory,
  EntityTypeSupportingNotes,
  EntityTypeSupportingRoutes,
  Group,
  Lead,
  MapEntity,
  Person,
  Route,
} from "@mapmycustomers/shared/types/entity";
import { EntityType, EntityTypesSupportedByImport } from "@mapmycustomers/shared/types/entity";
import type CustomField from "@mapmycustomers/shared/types/customField/CustomField";
import type { CustomFieldPayload } from "@mapmycustomers/shared/types/customField/CustomField";
import type PlatformListRequest from "@mapmycustomers/shared/types/viewModel/platformModel/PlatformListRequest";
import type TextSearchResult from "types/TextSearchResult";
import type Integration from "types/integrations/Integration";
import type IntegrationField from "types/integrations/IntegrationField";
import type IntegrationUser from "types/integrations/IntegrationUser";
import type Role from "@mapmycustomers/shared/types/Role";
import type Team from "@mapmycustomers/shared/types/Team";
import type Funnel from "@mapmycustomers/shared/types/entity/deals/Funnel";
import type Location from "@mapmycustomers/shared/types/Location";
import type Report from "types/Report";
import type RouteDetail from "@mapmycustomers/shared/types/entity/RouteDetail";
import type ActivityType from "@mapmycustomers/shared/types/entity/activities/ActivityType";
import invariant from "tiny-invariant";
import type { PlatformSimpleCondition } from "@mapmycustomers/shared/types/viewModel/platformModel/PlatformFilterModel";
import type IntegrationSync from "types/integrations/IntegrationSync";
import type FilePreview from "types/FilePreview";
import type Statistics from "types/Statistics";
import type Note from "@mapmycustomers/shared/types/entity/common/Note";
import type { StageSummary } from "@mapmycustomers/shared/types/entity/deals/StageSummary";
import type ImportPayload from "@mapmycustomers/shared/types/imports/ImportPayload";
import type Import from "@mapmycustomers/shared/types/imports/Import";
import type IntegrationRaw from "@mapmycustomers/shared/types/integrations/IntegrationRaw";
import type Stage from "@mapmycustomers/shared/types/entity/deals/Stage";
import type Identified from "@mapmycustomers/shared/types/base/Identified";
import type IntegrationFieldResponse from "types/integrations/IntegrationFieldResponse";
import type CreateIntegrationPayload from "types/integrations/CreateIntegrationPayload";
import { getFilesEndpoint } from "./utils/files";
import type Plan from "@mapmycustomers/shared/types/Plan";
import type DealLossReason from "@mapmycustomers/shared/types/entity/deals/DealLossReason";
import type PlatformIntegrationField from "types/integrations/PlatformIntegrationField";
import type InvitationInfo from "types/auth/InvitationInfo";
import Color from "@mapmycustomers/shared/enum/Color";
import type { Nullable, OnlyRequiredFields, OptionalFields } from "@mapmycustomers/shared/util/ts";
import NylasInfo, { CreateNylasEventPayload } from "types/nylas/NylasInfo";
import type { NylasInfoCreatePayload, NylasInfoUpdatePayload } from "types/nylas/NylasInfo";
import type CustomFieldValue from "@mapmycustomers/shared/types/customField/CustomFieldValue";
import type SavedFilter from "@mapmycustomers/shared/types/viewModel/SavedFilter";
import type { PlatformSavedFilter } from "@mapmycustomers/shared/types/viewModel/SavedFilter";
import type Setting from "@mapmycustomers/shared/types/Setting";
import parseDatedFields from "util/parsers/parseDatedFields";
import type CreateDealLossReasonPayload from "@mapmycustomers/shared/types/entity/deals/CreateDealLossReasonPayload";
import type { ActivityOrSuggestion } from "@mapmycustomers/shared/types/entity/ActivitySuggestion";
import Sandbox, {
  SandboxAccessTokenData,
  SandboxConfig,
} from "@mapmycustomers/shared/types/Sandbox";
import type {
  DuplicateEntity,
  DuplicatePair,
  DuplicateRun,
} from "@mapmycustomers/shared/types/entity/Duplicate";
import type Located from "@mapmycustomers/shared/types/base/Located";
import type { GeocodeLimits, GeocodeResult } from "@mapmycustomers/shared/types/base/Located";
import type MmcNotification from "types/MmcNotification";
import type NotificationListResponse from "types/viewModel/NotificationListResponse";
import type LeaderboardItem from "@mapmycustomers/shared/types/entity/LeaderboardItem";
import type LeaderboardMetricFieldName from "@mapmycustomers/shared/enum/fieldModel/LeaderboardMetricFieldName";
import type { MapEntry } from "@mapmycustomers/shared/types/map";
import type PlatformMapRequest from "@mapmycustomers/shared/types/viewModel/platformModel/PlatformMapRequest";
import type GeoPoint from "@mapmycustomers/shared/types/shapes/GeoPoint";
import type Territory from "@mapmycustomers/shared/types/entity/Territory";
import type LongLat from "@mapmycustomers/shared/types/base/LongLat";
import {
  addExtraCoordinate,
  addExtraCoordinateToTerritoryDetail,
  removeExtraCoordinate,
} from "store/api/utils/territories";
import type EntityHistoryRow from "@mapmycustomers/shared/types/entity/EntityHistoryRow";
import type PinLegend from "@mapmycustomers/shared/types/map/PinLegend";
import type {
  CreateColorPinLegendPayload,
  CreateShapePinLegendPayload,
  UpdateColorPinLegendPayload,
  UpdateShapePinLegendPayload,
} from "@mapmycustomers/shared/types/map/PinLegend";
import type AnyColor from "@mapmycustomers/shared/types/AnyColor";
import type AggregatedListResponse from "types/viewModel/AggregatedListResponse";
import type ListResponseAggregation from "types/viewModel/ListResponseAggregation";
import type AreaType from "@mapmycustomers/shared/types/territory/AreaType";
import type { GeoMultiPolygon, GeoPolygon } from "@mapmycustomers/shared/types/shapes";
import { geometryFromBinary, geometryToBinary } from "@mapmycustomers/shared/util/territory/binary";
import loggingService from "util/logging";
import type CumulDashboard from "@mapmycustomers/shared/types/cumul/CumulDashboard";
// TODO: do not use scene imports, fix all below this comment
import type ImportConfig from "@mapmycustomers/shared/types/imports/ImportConfig";
import type { RecurringActionType } from "component/createEditEntity/Activity/enum/RecurringActionType";
import FormLayout, { ChildLayout } from "@mapmycustomers/shared/types/layout/FormLayout";
import FormLayoutRoleAssociation, {
  FormLayoutRoleAssociationRecord,
} from "@mapmycustomers/shared/types/layout/FormLayoutRoleAssociation";
import SchemaField from "@mapmycustomers/shared/types/schema/SchemaField";
import { FieldAccessUpdate } from "scene/settings/component/ManageFields/type/FieldAccessUpdate";
import UserRecent, { UserRecentPayload } from "../../types/userRecent/UserRecent";
import CompanyWithLeadId from "@mapmycustomers/shared/types/entity/CompanyWithLeadId";
import PersonWithLeadId from "@mapmycustomers/shared/types/entity/PersonWithLeadId";
import Scope from "types/dashboard/Scope";
import Dashboard from "types/dashboard/Dashboard";
import UserDetails from "types/auth/UserDetails";
import UserRequestStatus from "enum/sso/UserRequestStatus";
import { SupportedSources } from "enum/SourceType";
import TimelineEvent, { BaseTimelineEvent, RawTimelineEvent } from "types/events/TimelineEvent";
import TimelineAction from "enum/TimelineAction";
import { convertFromRawDashboard, convertToRawDashboard } from "store/api/utils/dashboards";
import DemographicData from "scene/map/types/DemographicData";
import { LeadFinderLimit } from "scene/map/store/leadFinder/LeadFinderState";
import { default as MmcFile } from "@mapmycustomers/shared/types/File";
import { omit } from "lodash-es";

export type CompanyPayload = Partial<
  Omit<Company, "country" | "parentAccount" | "user"> & {
    country: string;
    countryCode: string;
    parentAccount: null | Pick<Company, "id">;
    user: { id: User["id"] };
  }
>;

export type PersonPayload = Partial<
  Omit<Person, "account" | "country" | "user"> & {
    account: null | Pick<Company, "id">;
    country: string;
    countryCode: string;
    user: { id: User["id"] };
  }
>;

export type DealPayload = Partial<
  Omit<Deal, "account" | "contact" | "dealLossReason" | "funnel" | "stage" | "user"> & {
    account: null | Pick<Company, "id">;
    contact: null | Pick<Person, "id">;
    dealLossReason: null | Pick<DealLossReason, "id">;
    funnel: Pick<Funnel, "id">;
    stage: Pick<Stage, "id">;
    user: { id: User["id"] };
  }
>;

export type CreateDealPayload = Partial<
  Omit<Deal, "account" | "contact" | "dealLossReason" | "funnel" | "stage" | "user" | "files"> & {
    account: null | Pick<Company, "id">;
    contact: null | Pick<Person, "id">;
    dealLossReason: null | Pick<DealLossReason, "id">;
    files: MmcFile["id"][];
    funnel: Pick<Funnel, "id">;
    stage: Pick<Stage, "id">;
    user: { id: User["id"] };
  }
>;

export type ActivityPayload = Partial<
  Omit<Activity, "account" | "assignee" | "contact" | "crmActivityType" | "deal" | "teamId"> & {
    account: null | Pick<Company, "id">;
    assigneeId: User["id"];
    contact: null | Pick<Person, "id">;
    crmActivityType?: Pick<ActivityType, "id">;
    deal: null | Pick<Deal, "id">;
    layoutId?: FormLayout["id"];
  }
>;

export type ActivityTypePayload = OptionalFields<
  Pick<ActivityType, "active" | "displayOrder" | "name">,
  "active"
>;

export interface EntityIdWithLayoutId<E extends Identified> {
  id: E["id"];
  layoutId: FormLayout["id"];
}

const entityTypeToCustomFieldsEndpoint: { [key in EntityTypeSupportingCustomFields]: string } = {
  [EntityType.ACTIVITY]: "crm-activity-custom-fields",
  [EntityType.COMPANY]: "account-custom-fields",
  [EntityType.DEAL]: "deal-custom-fields",
  [EntityType.PERSON]: "contact-custom-fields",
};

const entityTypeToGroupsEndpoint: { [key in EntityTypeSupportingGroups]: string } = {
  [EntityType.COMPANY]: "account-groups",
  [EntityType.DEAL]: "deal-groups",
  [EntityType.PERSON]: "contact-groups",
} as const;

const updateRouteStopsFieldName: { [key in EntityTypeSupportingRoutes]: string } = {
  [EntityType.COMPANY]: "routeAccountGroups",
  [EntityType.PERSON]: "routeContactGroups",
} as const;

const updateRouteStopFieldName: Record<EntityTypeSupportingRoutes, string> = {
  [EntityType.COMPANY]: "accountId",
  [EntityType.PERSON]: "contactId",
} as const;

const upsertCustomFieldPayloadPart: Record<EntityTypeSupportingCustomFields, string> = {
  [EntityType.ACTIVITY]: "crmActivityId",
  [EntityType.COMPANY]: "accountId",
  [EntityType.DEAL]: "dealId",
  [EntityType.PERSON]: "contactId",
} as const;

const entityTypeToDuplicates: { [key in EntityTypeSupportingDataMerging]: string } = {
  [EntityType.COMPANY]: "account-dupes",
  [EntityType.PERSON]: "contact-dupes",
} as const;

const mergeEntity: { [key in EntityTypeSupportingDataMerging]: string } = {
  [EntityType.COMPANY]: "account-dupe-merge",
  [EntityType.PERSON]: "contact-dupe-merge",
} as const;

const entityTypeToDuplicateSearch: { [key in EntityTypeSupportingDataMerging]: string } = {
  [EntityType.COMPANY]: "accounts",
  [EntityType.PERSON]: "contacts",
} as const;

const entityTypeToHistoryEndpointEndpoint: { [key in EntityTypeSupportingHistory]: string } = {
  [EntityType.ACTIVITY]: "crm-activity-history",
  [EntityType.COMPANY]: "account-history",
  [EntityType.DEAL]: "deal-history",
  [EntityType.PERSON]: "contact-history",
} as const;

export class ApiService extends BaseApiService {
  protected getAuthToken(): string | undefined {
    return authService.getToken();
  }

  authorize = ({
    grant_type,
    password,
    refresh_token,
    scope,
    username,
  }: {
    grant_type?: string;
    password?: string;
    refresh_token?: string;
    scope?: string;
    username?: string;
  }): Promise<{ accessToken?: string; message?: string }> => {
    return this.post(
      "/oauth/token",
      qs.stringify({
        ...(username ? { username } : {}),
        ...(password ? { password } : {}),
        ...(refresh_token ? { refresh_token } : {}),
        client_id: "mmc-web2-client-id",
        grant_type: grant_type ?? "password",
        scope: scope ?? "test",
      }),
      {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      }
    );
  };

  authorizeCumul = (
    orgId: Organization["id"],
    config?: RequestOptions
  ): Promise<{ token?: string }> => this.post(`/organizations/${orgId}/cumul/auth`, config);

  authorizeSso = (sessionId: string): Promise<{ accessToken?: string; message?: string }> => {
    return this.get(`/sso/login/${sessionId}`);
  };

  requestAccountSso = (sessionId: string): Promise<{ accessToken?: string; message?: string }> => {
    return this.get(`/sso/request-access/${sessionId}`);
  };

  getCumulDashboardList = (
    orgId: Organization["id"],
    payload: {
      cumulSSOKey: string;
      cumulSSOToken: string;
    },
    { $order }: Partial<PlatformListRequest> = {},
    config?: RequestOptions
  ): Promise<ListResponse<CumulDashboard>> =>
    this.post(
      `/organizations/${orgId}/cumul/dashboards`,
      {
        ...payload,
        $order,
      },
      config
    );

  deleteDashboard = (
    orgId: Organization["id"],
    dashboardId: CumulDashboard["id"],
    details: {
      cumulSSOKey: string;
      cumulSSOToken: string;
    },
    config?: RequestOptions
  ): Promise<void> =>
    this.delete(`/organizations/${orgId}/cumul/dashboards/${dashboardId}`, {
      data: details,
      ...config,
    });

  duplicateDashboard = (
    orgId: Organization["id"],
    dashboardId: CumulDashboard["id"],
    details: {
      cumulSSOKey: string;
      cumulSSOToken: string;
      dashboardId: CumulDashboard["id"];
      userId: User["id"][];
    },
    config?: RequestOptions
  ): Promise<CumulDashboard> =>
    this.post(`/organizations/${orgId}/cumul/dashboards/${dashboardId}/clone`, details, config);

  createDashboard = (
    orgId: Organization["id"],
    details: {
      cumulSSOKey: string;
      cumulSSOToken: string;
    },
    config?: RequestOptions
  ): Promise<CumulDashboard> =>
    this.post(`/organizations/${orgId}/cumul/dashboards/create`, details, config);

  fetchMe = (config?: RequestOptions): Promise<Iam> => this.get("/users/me", config);

  fetchRoles = (orgId: Organization["id"], config?: RequestOptions): Promise<ListResponse<Role>> =>
    this.get(`/organizations/${orgId}/roles?$order=priority`, config);

  fetchTeams = (orgId: Organization["id"], config?: RequestOptions): Promise<ListResponse<Team>> =>
    this.get(`/organizations/${orgId}/teams`, config);

  getUser = (
    orgId: Organization["id"],
    userId: User["id"],
    config?: RequestOptions
  ): Promise<User> => this.get(`/organizations/${orgId}/users/${userId}`, config);

  fetchUsers = (orgId: Organization["id"], config?: RequestOptions): Promise<ListResponse<Team>> =>
    this.get(`/organizations/${orgId}/users`, { params: { $limit: -1 }, ...config });

  updateMe = (
    payload: OnlyRequiredFields<Nullable<User>, "id">,
    config?: RequestOptions
  ): Promise<User> => this.put("/users/me", payload, config);

  getOrganization = (orgId: Organization["id"], config?: RequestOptions): Promise<Organization> =>
    this.get(`/organizations/${orgId}`, config);

  getTeam = (
    orgId: Organization["id"],
    teamId: Team["id"],
    config?: RequestOptions
  ): Promise<Team> => this.get(`/organizations/${orgId}/teams/${teamId}`, config);

  updateOrganization = (
    payload: OnlyRequiredFields<Organization, "id">,
    config?: RequestOptions
  ): Promise<Organization> => this.put(`/organizations/${payload.id}`, payload, config);

  updateOrganizationSetting = (
    orgId: Organization["id"],
    payload: Setting,
    config?: RequestOptions
  ): Promise<Setting> =>
    this.put(`/organizations/${orgId}/settings/${payload.id}`, payload, config);

  updateTeam = (
    orgId: Organization["id"],
    payload: OnlyRequiredFields<Team, "id">,
    config?: RequestOptions
  ): Promise<Team> => this.put(`/organizations/${orgId}/teams/${payload.id}`, payload, config);

  updateUser = (
    orgId: Organization["id"],
    payload: OnlyRequiredFields<User, "id">,
    config?: RequestOptions
  ): Promise<User> => this.put(`/organizations/${orgId}/users/${payload.id}`, payload, config);

  fetchGeocodeLimits = (
    orgId: Organization["id"],
    config?: RequestOptions
  ): Promise<GeocodeLimits> => this.get(`organizations/${orgId}/geocode/limits`, config);

  fetchCurrencies = (): Promise<SimpleListResponse<Currency>> => this.get("currencies?$limit=1000");

  fetchCustomFields = async (
    orgId: Organization["id"],
    entityType: EntityTypeSupportingCustomFields,
    config: RequestOptions = { params: { $limit: 1000, $order: "displayOrder" } }
  ): Promise<SimpleListResponse<CustomField>> => {
    const response: SimpleListResponse<CustomField> = await this.get(
      `/organizations/${orgId}/${entityTypeToCustomFieldsEndpoint[entityType]}`,
      config
    );
    // sometimes custom fields have equal display order, here we guarantee that each field will have a unique display order
    response.data.forEach((customField, index) => {
      customField.displayOrder = index + 1;
    });
    return response;
  };

  deleteCustomField = (
    orgId: Organization["id"],
    entityType: EntityTypeSupportingCustomFields,
    id: CustomField["id"],
    sendReport: boolean,
    config?: RequestOptions
  ): Promise<SimpleListResponse<CustomField>> =>
    this.delete(`/organizations/${orgId}/${entityTypeToCustomFieldsEndpoint[entityType]}/${id}`, {
      ...config,
      ...(sendReport ? { params: { $filters: JSON.stringify({ export: true }) } } : {}),
    });

  createCustomField = (
    orgId: Organization["id"],
    entityType: EntityTypeSupportingCustomFields,
    customField: CustomFieldPayload,
    config?: RequestOptions
  ): Promise<CustomField> =>
    this.post(
      `/organizations/${orgId}/${entityTypeToCustomFieldsEndpoint[entityType]}`,
      customField,
      config
    );

  updateCustomField = (
    orgId: Organization["id"],
    entityType: EntityTypeSupportingCustomFields,
    customField: CustomField,
    config?: RequestOptions
  ): Promise<CustomField> =>
    this.put(
      `/organizations/${orgId}/${entityTypeToCustomFieldsEndpoint[entityType]}/${customField.id}`,
      customField,
      config
    );

  updateCustomFieldBulk = (
    orgId: Organization["id"],
    entityType: EntityTypeSupportingCustomFields,
    customFields: CustomField[],
    config?: RequestOptions
  ): Promise<SimpleListResponse<CustomField>> =>
    this.put(
      `/organizations/${orgId}/${entityTypeToCustomFieldsEndpoint[entityType]}/bulk`,
      customFields,
      config
    );

  upsertCustomFieldsValues = (
    update: boolean,
    orgId: Organization["id"],
    layoutId: FormLayout["id"],
    entityType: EntityTypeSupportingCustomFields,
    entityId: EntitySupportingCustomFields["id"],
    customFieldsValues: CustomFieldValue[],
    config?: RequestOptions
  ): Promise<ListResponse<CustomField>> => {
    const payload = [
      {
        customFieldData: customFieldsValues.map(({ customField, value }) => ({
          customField,
          value: value ?? [],
        })),
        [upsertCustomFieldPayloadPart[entityType]]: entityId,
      },
    ];
    const url = `/organizations/${orgId}/${entityType}/custom-field-values/upsert`;
    return (update ? this.put.bind(this) : this.post.bind(this))(url, payload, {
      ...config,
      params: {
        ...config?.params,
        $limit: 1000,
        $order: "displayOrder",
        layoutId,
      },
    });
  };

  bulkUpsertCustomFieldsValues = (
    update: boolean,
    orgId: Organization["id"],
    entityType: EntityTypeSupportingCustomFields,
    entitiesInfo: EntityIdWithLayoutId<EntitySupportingCustomFields>[],
    customFieldValues: CustomFieldValue[],
    config?: RequestOptions
  ): Promise<ListResponse<CustomField>> => {
    const customFieldData = customFieldValues.map(({ customField, value }) => ({
      customField,
      value: value ?? [],
    }));

    const payload = entitiesInfo.map(({ id, layoutId }) => ({
      customFieldData,
      layoutId,
      [upsertCustomFieldPayloadPart[entityType]]: id,
    }));

    return (update ? this.put.bind(this) : this.post.bind(this))(
      `/organizations/${orgId}/${entityType}/custom-field-values/upsert`,
      payload,
      config
    );
  };

  createActivityType = async (
    orgId: Organization["id"],
    payload: ActivityTypePayload,
    config?: RequestOptions
  ): Promise<ActivityType> =>
    this.post(`/organizations/${orgId}/crm-activity-types`, payload, config);

  updateActivityType = async (
    orgId: Organization["id"],
    payload: OnlyRequiredFields<ActivityType, "id">,
    config?: RequestOptions
  ): Promise<ActivityType> =>
    this.put(`/organizations/${orgId}/crm-activity-types/${payload.id}`, payload, config);

  deleteActivityType = async (
    orgId: Organization["id"],
    activityTypeId: ActivityType["id"]
  ): Promise<ActivityType> =>
    this.delete(`/organizations/${orgId}/crm-activity-types/${activityTypeId}`);

  fetchGroups = (
    orgId: Organization["id"],
    entityType: EntityTypeSupportingGroups,
    { $filters, $limit, $offset, $order }: Partial<Omit<PlatformListRequest, "$columns">> = {
      $order: "name",
    },
    config?: RequestOptions
  ): Promise<ListResponse<CustomField>> =>
    this.get(`/organizations/${orgId}/${entityTypeToGroupsEndpoint[entityType]}`, {
      params: {
        $filters: $filters ? JSON.stringify($filters) : undefined,
        $limit,
        $offset,
        $order,
      },
      ...config,
    });

  fetchGroup = (
    orgId: Organization["id"],
    entityType: EntityTypeSupportingGroups,
    groupId: Group["id"],
    options?: {
      includeAccessStatus?: boolean;
    },
    config?: RequestOptions
  ): Promise<Group> => {
    const $filters = { includeAccessStatus: true, ...(options ?? {}) };
    return this.get(
      `/organizations/${orgId}/${entityTypeToGroupsEndpoint[entityType]}/${groupId}`,
      {
        params: {
          $filters: JSON.stringify($filters),
        },
        ...config,
      }
    );
  };

  fetchActivityTypes = (
    orgId: Organization["id"],
    { $columns, $filters, $limit, $offset, $order }: Partial<PlatformListRequest> = {},
    config?: RequestOptions
  ): Promise<ListResponse<ActivityType>> =>
    this.get(`/organizations/${orgId}/crm-activity-types`, {
      params: {
        $columns: $columns ? $columns.join(",") : undefined,
        $filters: $filters ? JSON.stringify($filters) : undefined,
        $limit,
        $offset,
        $order,
      },
      ...config,
    });

  fetchActivities = (
    orgId: Organization["id"],
    { $aggs, $columns, $filters, $limit, $offset, $order }: Partial<PlatformListRequest> = {},
    config?: RequestOptions
  ): Promise<ListResponse<Activity>> =>
    this.get(`/organizations/${orgId}/crm-activities`, {
      params: {
        $aggs: $aggs ? JSON.stringify($aggs) : undefined,
        $columns: $columns ? $columns.join(",") : undefined,
        $filters: $filters ? JSON.stringify($filters) : undefined,
        $limit,
        $offset,
        $order,
      },
      ...config,
    });

  fetchMappedActivities = (
    orgId: Organization["id"],
    { $aggs, $columns, $filters, $limit, $offset, $order }: Partial<PlatformListRequest> = {},
    config?: RequestOptions
  ): Promise<ListResponse<Activity>> =>
    this.get(`/organizations/${orgId}/crm-activities`, {
      params: {
        $aggs: $aggs ? JSON.stringify($aggs) : undefined,
        $columns: $columns ? $columns.join(",") : undefined,
        $filters: $filters ? JSON.stringify($filters) : undefined,
        $limit,
        $offset,
        $order,
      },
      ...config,
    });

  fetchLeaderboardData = (
    orgId: Organization["id"],
    filters: Partial<{
      crmActivityTypeId: { $in: Array<ActivityType["id"]> };
      date: PlatformSimpleCondition;
      funnelId: { $in: Array<Funnel["id"]> };
      includePerformanceMetric: boolean;
      property: { $in: LeaderboardMetricFieldName[] };
      source: { $in: Array<SupportedSources> };
      stageId: { $in: Array<Stage["id"]> };
      summary: "all" | "team" | "user";
      teamId: { $in: Array<Team["id"]> };
      userId: { $in: Array<User["id"]> };
    }>,
    order: string | undefined,
    config?: RequestOptions
  ): Promise<ListResponse<LeaderboardItem>> =>
    this.get(`/organizations/${orgId}/stats?inAppLeaderboard=true`, {
      params: {
        $filters: filters ? JSON.stringify(filters) : undefined,
        $order: order,
        $limit: 1000,
      },
      ...config,
    });

  fetchActivitiesAndSuggestions = (
    orgId: Organization["id"],
    { $columns, $filters, $limit, $offset, $order }: Partial<PlatformListRequest> = {},
    config?: RequestOptions
  ): Promise<ListResponse<ActivityOrSuggestion>> =>
    this.get(`/organizations/${orgId}/crm-activities`, {
      params: {
        $columns: $columns ? $columns.join(",") : undefined,
        $filters: JSON.stringify({ ...($filters ?? {}), includeSuggestions: true }),
        $limit,
        $offset,
        $order,
      },
      ...config,
    });

  fetchCompanies = (
    orgId: Organization["id"],
    { $aggs, $columns, $filters, $limit, $offset, $order }: Partial<PlatformListRequest> = {},
    config?: RequestOptions
  ): Promise<ListResponse<Company>> =>
    this.get(`/organizations/${orgId}/accounts`, {
      params: {
        $aggs: $aggs ? JSON.stringify($aggs) : undefined,
        $columns: $columns ? $columns.join(",") : undefined,
        $filters: $filters ? JSON.stringify($filters) : undefined,
        $limit,
        $offset,
        $order,
      },
      ...config,
    });

  fetchEntityDuplicates = (
    orgId: Organization["id"],
    entityType: EntityTypeSupportingDataMerging,
    { $filters }: Partial<PlatformListRequest> = {},
    config?: RequestOptions
  ): Promise<ListResponse<DuplicateEntity>> =>
    this.get(`/organizations/${orgId}/${entityTypeToDuplicateSearch[entityType]}`, {
      params: {
        $filters: $filters ? JSON.stringify($filters) : undefined,
      },
      ...config,
    });

  updateCompany = async (
    orgId: Organization["id"],
    layoutId: FormLayout["id"] | undefined,
    payload: OnlyRequiredFields<CompanyPayload, "id">,
    options?: {
      cadence?: boolean;
      includeAccessStatus?: boolean;
      includeCustomFields?: boolean;
      includeGroups?: boolean;
      includeNotes?: boolean;
      includeRoutes?: boolean;
      includeTerritories?: boolean;
      includeUsersWithAccess?: boolean;
    },
    config?: RequestOptions
  ): Promise<Company> => {
    if (!layoutId) {
      loggingService.debug(`Layout ID should be provided in updateCompany request`, { payload });
    }
    await this.put(`/organizations/${orgId}/accounts/${payload.id}`, payload, {
      ...config,
      params: { ...config?.params, ignoreLayout: layoutId ? undefined : true, layoutId },
    });
    const getOptions = { includeAccessStatus: true, ...(options ?? {}) };
    return this.fetchCompany(orgId, payload.id, getOptions);
  };

  cumulDemoRequest = async (
    token: string,
    message: string,
    config?: RequestOptions
  ): Promise<void> =>
    this.post(
      `/email/mmc`,
      {
        message,
        subject: "A customer is interested in Advanced Reporting [Internal Alert]",
        token,
      },
      config
    );

  fetchFunnels = (
    orgId: Organization["id"],
    { $columns, $filters, $limit, $offset, $order }: Partial<PlatformListRequest> = {},
    config?: RequestOptions
  ): Promise<ListResponse<Funnel>> =>
    this.get(`/organizations/${orgId}/funnels`, {
      params: {
        $columns: $columns ? $columns.join(",") : undefined,
        $filters: $filters ? JSON.stringify($filters) : undefined,
        $limit,
        $offset,
        $order,
      },
      ...config,
    });

  fetchFunnelSummary = (
    orgId: Organization["id"],
    funnelId: Funnel["id"],
    { $filters }: Partial<PlatformListRequest> = {},
    config?: RequestOptions
  ): Promise<ListResponse<StageSummary>> =>
    this.get(`organizations/${orgId}/reports/funnels/${funnelId}/summary`, {
      params: {
        $filters: $filters ? JSON.stringify($filters) : undefined,
      },
      ...config,
    });

  fetchLocationSummary = (
    orgId: Organization["id"],
    config?: RequestOptions
  ): Promise<ListResponse<Location>> =>
    this.get(`/organizations/${orgId}/reports/locations/summary`, config);

  fetchStages = (
    orgId: Organization["id"],
    funnelId: Funnel["id"],
    { $columns, $filters, $limit, $offset, $order }: Partial<PlatformListRequest> = {},
    config?: RequestOptions
  ): Promise<ListResponse<Stage>> =>
    this.get(`/organizations/${orgId}/funnels/${funnelId}/stages`, {
      params: {
        $columns: $columns ? $columns.join(",") : undefined,
        $filters: $filters ? JSON.stringify($filters) : undefined,
        $limit,
        $offset,
        $order,
      },
      ...config,
    });

  fetchDeals = (
    orgId: Organization["id"],
    { $aggs, $columns, $filters, $limit, $offset, $order }: Partial<PlatformListRequest> = {},
    config?: RequestOptions
  ): Promise<ListResponse<Deal>> =>
    this.get(`/organizations/${orgId}/deals`, {
      params: {
        $aggs: $aggs ? JSON.stringify($aggs) : undefined,
        $columns: $columns ? $columns.join(",") : undefined,
        $filters: $filters ? JSON.stringify($filters) : undefined,
        $limit,
        $offset,
        $order,
      },
      ...config,
    });

  fetchPeople = (
    orgId: Organization["id"],
    { $aggs, $columns, $filters, $limit, $offset, $order }: Partial<PlatformListRequest> = {},
    config?: RequestOptions
  ): Promise<ListResponse<Person>> =>
    this.get(`/organizations/${orgId}/contacts`, {
      params: {
        $aggs: $aggs ? JSON.stringify($aggs) : undefined,
        $columns: $columns ? $columns.join(",") : undefined,
        $filters: $filters ? JSON.stringify($filters) : undefined,
        $limit,
        $offset,
        $order,
      },
      ...config,
    });

  createPerson = async (
    orgId: Organization["id"],
    layoutId: FormLayout["id"],
    payload: PersonPayload,
    options?: {
      includeAccessStatus?: boolean;
    },
    config?: RequestOptions
  ): Promise<Company> => {
    const $filters = { includeAccessStatus: true, ...(options ?? {}) };
    return this.post(`/organizations/${orgId}/contacts`, payload, {
      params: {
        $filters: JSON.stringify($filters),
        layoutId,
      },
      ...config,
    });
  };

  updatePerson = async (
    orgId: Organization["id"],
    layoutId: FormLayout["id"] | undefined,
    payload: OnlyRequiredFields<PersonPayload, "id">,
    options?: {
      cadence?: boolean;
      includeAccessStatus?: boolean;
      includeCustomFields?: boolean;
      includeGroups?: boolean;
      includeNotes?: boolean;
      includeRoutes?: boolean;
      includeUsersWithAccess?: boolean;
    },
    config?: RequestOptions
  ): Promise<Person> => {
    if (!layoutId) {
      loggingService.debug(`Layout ID should be provided in updatePerson request`, { payload });
    }
    await this.put(`/organizations/${orgId}/contacts/${payload.id}`, payload, {
      ...config,
      params: { ...config?.params, ignoreLayout: layoutId ? undefined : true, layoutId },
    });
    const getOptions = { includeAccessStatus: true, ...(options ?? {}) };
    return this.fetchPerson(orgId, payload.id, getOptions);
  };

  fetchCompanyRoutes = (
    orgId: Organization["id"],
    { $columns, $filters, $limit, $offset, $order }: Partial<PlatformListRequest> = {},
    config?: RequestOptions
  ): Promise<ListResponse<Route>> =>
    this.get(`/organizations/${orgId}/account-routes`, {
      params: {
        $columns: $columns ? $columns.join(",") : undefined,
        $filters: $filters ? JSON.stringify($filters) : undefined,
        $limit,
        $offset,
        $order,
      },
      ...config,
    });

  fetchCompanyRoute = (
    orgId: Organization["id"],
    routeId: Route["id"],
    includeCompanies: boolean,
    options?: {
      includeAccessStatus: boolean;
    },
    config?: RequestOptions
  ): Promise<Route> =>
    this.get(`/organizations/${orgId}/account-routes/${routeId}`, {
      params: {
        $filters: includeCompanies
          ? JSON.stringify({ includeAccounts: true, ...(options ?? {}) })
          : JSON.stringify(options),
      },
      ...config,
    });

  fetchPeopleRoutes = (
    orgId: Organization["id"],
    { $columns, $filters, $limit, $offset, $order }: Partial<PlatformListRequest> = {},
    config?: RequestOptions
  ): Promise<ListResponse<Route>> =>
    this.get(`/organizations/${orgId}/routes`, {
      params: {
        $columns: $columns ? $columns.join(",") : undefined,
        $filters: $filters ? JSON.stringify($filters) : undefined,
        $limit,
        $offset,
        $order,
      },
      ...config,
    });

  fetchPeopleRoute = (
    orgId: Organization["id"],
    routeId: Route["id"],
    includePeople: boolean,
    options?: {
      includeAccessStatus: boolean;
    },
    config?: RequestOptions
  ): Promise<Route> =>
    this.get(`/organizations/${orgId}/routes/${routeId}`, {
      params: {
        $filters: includePeople
          ? JSON.stringify({ includeContacts: true, ...(options ?? {}) })
          : JSON.stringify(options),
      },
      ...config,
    });

  buildRoute = (
    orgId: Organization["id"],
    entityType: EntityTypeSupportingRoutes,
    payload: {
      endGeoPoint?: RouteDetail["startGeoPoint"];
      routeAccounts?: Array<Company["id"]>;
      routeContacts?: Array<Person["id"]>;
      routeType: RouteDetail["type"];
      startGeoPoint?: RouteDetail["startGeoPoint"];
    },
    config?: RequestOptions
  ): Promise<Route> =>
    this.post(
      entityType === EntityType.COMPANY
        ? `/organizations/${orgId}/routes/accounts/preview`
        : `/organizations/${orgId}/routes/preview`,
      payload,
      config
    );

  updateRouteStops = (
    orgId: Organization["id"],
    entityType: EntityTypeSupportingRoutes,
    routeId: Route["id"],
    entityIds: Array<Company["id"]> | Array<Person["id"]>,
    allottedTime: number,
    config?: RequestOptions
  ): Promise<Route> =>
    this.post(
      entityType === EntityType.COMPANY
        ? `/organizations/${orgId}/routes/${routeId}/accounts/bulk`
        : `/organizations/${orgId}/routes/${routeId}/contacts/bulk`,
      {
        [updateRouteStopsFieldName[entityType]]: entityIds.map((entityId) => ({
          _action_: "create",
          allottedTime,
          [updateRouteStopFieldName[entityType]]: entityId,
        })),
      },
      config
    );

  createRoute = (
    orgId: Organization["id"],
    entityType: EntityTypeSupportingRoutes,
    payload: Partial<Route>,
    options?: {
      includeAccessStatus?: boolean;
    },
    config?: RequestOptions
  ): Promise<Route> => {
    const $filters = { includeAccessStatus: true, ...(options ?? {}) };
    return this.post(
      entityType === EntityType.COMPANY
        ? `/organizations/${orgId}/account-routes`
        : `/organizations/${orgId}/routes`,
      payload,
      {
        params: {
          $filters: JSON.stringify($filters),
        },
        ...config,
      }
    );
  };

  removeCompaniesFromRoute = (
    orgId: Organization["id"],
    routeId: Route["id"],
    companyIds: Company["id"][]
  ): Promise<void> =>
    this.post(`/organizations/${orgId}/routes/${routeId}/accounts/bulk`, {
      routeAccountGroups: companyIds.map((accountId) => ({ _action_: "delete", accountId })),
    });

  removePeopleFromRoute = (
    orgId: Organization["id"],
    routeId: Route["id"],
    peopleIds: Person["id"][]
  ): Promise<void> =>
    this.post(`/organizations/${orgId}/routes/${routeId}/contacts/bulk`, {
      routeContactGroups: peopleIds.map((contactId) => ({ _action_: "delete", contactId })),
    });

  fetchLocations = (
    orgId: Organization["id"],
    { $columns, $filters, $limit, $offset, $order }: Partial<PlatformListRequest> = {},
    config?: RequestOptions
  ): Promise<ListResponse<Location>> =>
    this.get(`/organizations/${orgId}/locations`, {
      params: {
        $columns: $columns ? $columns.join(",") : undefined,
        $filters: $filters ? JSON.stringify($filters) : undefined,
        $limit,
        $offset,
        $order,
      },
      ...config,
    });

  fetchAllFunnelSummary = (
    orgId: Organization["id"],
    funnelId: Funnel["id"],
    { $columns, $filters, $limit, $offset, $order }: Partial<PlatformListRequest>,
    config?: RequestOptions
  ): Promise<ListResponse<StageSummary>> =>
    this.get(`/organizations/${orgId}/reports/funnels/${funnelId}/summary`, {
      params: {
        $columns: $columns ? $columns.join(",") : undefined,
        $filters: $filters ? JSON.stringify($filters) : undefined,
        $limit,
        $offset,
        $order,
      },
      ...config,
    });

  fetchAllUsers = (
    orgId: Organization["id"],
    config?: RequestOptions
  ): Promise<SimpleListResponse<User>> => this.get(`/organizations/${orgId}/users`, config);

  fetchReports = async (
    orgId: Organization["id"],
    { $columns, $filters, $limit, $offset, $order }: Partial<PlatformListRequest> = {},
    config?: RequestOptions
  ): Promise<ListResponse<Report>> => {
    const response: ListResponse<Report> = await this.get(`/organizations/${orgId}/reports`, {
      params: {
        $columns: $columns ? $columns.join(",") : undefined,
        $filters: $filters ? JSON.stringify($filters) : undefined,
        $limit,
        $offset,
        $order,
      },
      ...config,
    });

    // this hack is needed because reports endpoint returns "activities" instead of "crm-activities"
    response.data.forEach((report) => {
      if ((report.tableName as string) === "activities") {
        report.tableName = EntityType.ACTIVITY;
      }
    });

    return response;
  };

  createIntegration = (
    orgId: Organization["id"],
    payload: CreateIntegrationPayload,
    config?: RequestOptions
  ): Promise<Integration> => this.post(`/organizations/${orgId}/integrations`, payload, config);

  fetchIntegrations = (
    orgId: Organization["id"],
    config?: RequestOptions
  ): Promise<ListResponse<IntegrationRaw>> =>
    this.get(`/organizations/${orgId}/integrations`, config);

  fetchIntegration = (
    orgId: Organization["id"],
    integrationId: Integration["id"],
    config?: RequestOptions
  ): Promise<IntegrationRaw> =>
    this.get(`/organizations/${orgId}/integrations/${integrationId}`, config);

  deleteIntegration = (
    orgId: Organization["id"],
    integrationId: Integration["id"],
    config?: RequestOptions
  ): Promise<Integration> =>
    this.delete(`/organizations/${orgId}/integrations/${integrationId}`, config);

  updateIntegration = (
    orgId: Organization["id"],
    integrationId: Integration["id"],
    payload: Pick<Integration, "id" | "isLocked" | "syncOptions">,
    config?: RequestOptions
  ): Promise<Integration> =>
    this.put(`/organizations/${orgId}/integrations/${integrationId}`, payload, config);

  fetchIntegrationUsers = (
    orgId: Organization["id"],
    integrationId: Integration["id"],
    { $columns, $filters, $limit, $offset, $order }: Partial<PlatformListRequest>,
    config?: RequestOptions
  ): Promise<ListResponse<IntegrationUser>> =>
    this.get(`/organizations/${orgId}/integrations/${integrationId}/users`, {
      params: {
        $columns: $columns ? $columns.join(",") : undefined,
        $filters: $filters ? JSON.stringify($filters) : undefined,
        $limit,
        $offset,
        $order: Array.isArray($order) ? $order.join(",") : $order ? $order : undefined,
      },
      ...config,
    });

  fetchIntegrationSyncs = (
    orgId: Organization["id"],
    integrationId: Integration["id"],
    { $columns, $filters, $limit, $offset, $order }: Partial<PlatformListRequest>,
    config?: RequestOptions
  ): Promise<ListResponse<IntegrationSync>> =>
    this.get(`/organizations/${orgId}/integrations/${integrationId}/integration-syncs`, {
      params: {
        $columns: $columns ? $columns.join(",") : undefined,
        $filters: $filters ? JSON.stringify($filters) : undefined,
        $limit,
        $offset,
        $order,
      },
      ...config,
    });

  updateIntegrationUsers = (
    orgId: Organization["id"],
    integrationId: Integration["id"],
    payload: Pick<IntegrationUser, "id" | "syncing" | "userId">[],
    config?: RequestOptions
  ): Promise<ListResponse<IntegrationUser>> =>
    this.put(
      `/organizations/${orgId}/integrations/${integrationId}/users/bulk-update`,
      payload,
      config
    );

  fetchIntegrationFields = (
    orgId: Organization["id"],
    integrationId: Integration["id"],
    { $filters, $limit, $offset, $order }: Partial<Omit<PlatformListRequest, "$columns">>,
    config?: RequestOptions
  ): Promise<ListResponse<IntegrationField>> =>
    this.get(`/organizations/${orgId}/integrations/${integrationId}/fields`, {
      params: {
        $filters: $filters ? JSON.stringify($filters) : undefined,
        $limit,
        $offset,
        $order,
      },
      ...config,
    });

  updateIntegrationFields = (
    orgId: Organization["id"],
    integrationId: Integration["id"],
    payload: Partial<Record<EntityTypesSupportedByIntegrations, PlatformIntegrationField[]>>,
    config?: RequestOptions
  ): Promise<Partial<Record<EntityTypesSupportedByIntegrations, IntegrationFieldResponse[]>>> =>
    this.put(
      `/organizations/${orgId}/integrations/${integrationId}/fields/mapping`,
      payload,
      config
    );

  fetchReport = async (
    orgId: Organization["id"],
    reportId: Report["id"],
    config?: RequestOptions
  ): Promise<Report> => this.get(`/organizations/${orgId}/reports/${reportId}`, config);

  updateReport = async (
    orgId: Organization["id"],
    report: Partial<Report>,
    config?: RequestOptions
  ): Promise<Report> => this.put(`/organizations/${orgId}/reports/${report.id}`, report, config);

  createReport = async (
    orgId: Organization["id"],
    report: Omit<Partial<Report>, "id">,
    config?: RequestOptions
  ): Promise<Report> => this.post(`/organizations/${orgId}/reports`, report, config);

  generateReport = async (
    orgId: Organization["id"],
    reportId: Report["id"],
    email: string,
    config?: RequestOptions
  ): Promise<void> => {
    await this.post(
      `/organizations/${orgId}/reports/${reportId}/generate`,
      { username: email },
      config
    );
  };

  cloneReport = async (
    orgId: Organization["id"],
    reportId: Report["id"],
    config?: RequestOptions
  ): Promise<Report> => {
    return this.post(`/organizations/${orgId}/reports/clone`, { id: reportId }, config);
  };

  deleteReport = async (
    orgId: Organization["id"],
    reportId: Report["id"],
    config?: RequestOptions
  ): Promise<void> => {
    await this.delete(`/organizations/${orgId}/reports/${reportId}`, config);
  };

  fetchCompany = (
    orgId: Organization["id"],
    id: Company["id"],
    options?: {
      cadence?: boolean;
      includeAccessStatus?: boolean;
      includeCustomFields?: boolean;
      includeGroups?: boolean;
      includeNotes?: boolean;
      includeRoutes?: boolean;
      includeTerritories?: boolean;
      includeUsersWithAccess?: boolean;
    },
    config?: RequestOptions
  ): Promise<Company> =>
    this.get(`/organizations/${orgId}/accounts/${id}`, {
      params: {
        $filters: options ? JSON.stringify(options) : undefined,
      },
      ...config,
    });

  deleteCompany = (
    orgId: Organization["id"],
    id: Company["id"],
    config?: RequestOptions
  ): Promise<void> => this.delete(`/organizations/${orgId}/accounts/${id}`, config);

  fetchPerson = (
    orgId: Organization["id"],
    id: Person["id"],
    options?: {
      cadence?: boolean;
      includeAccessStatus?: boolean;
      includeCustomFields?: boolean;
      includeGroups?: boolean;
      includeNotes?: boolean;
      includeRoutes?: boolean;
      includeTerritories?: boolean;
      includeUsersWithAccess?: boolean;
    },
    config?: RequestOptions
  ): Promise<Person> =>
    this.get(`/organizations/${orgId}/contacts/${id}`, {
      params: {
        $filters: options ? JSON.stringify(options) : undefined,
      },
      ...config,
    });

  deletePerson = (
    orgId: Organization["id"],
    id: Person["id"],
    config?: RequestOptions
  ): Promise<void> => this.delete(`/organizations/${orgId}/contacts/${id}`, config);

  fetchDeal = (
    orgId: Organization["id"],
    id: Deal["id"],
    options?: {
      includeAccessStatus?: boolean;
      includeCustomFields?: boolean;
      includeFiles?: boolean;
      includeGroups?: boolean;
      includeNotes?: boolean;
      includeTerritories?: boolean;
      includeUsersWithAccess?: boolean;
    },
    config?: RequestOptions
  ): Promise<Deal> =>
    this.get(`/organizations/${orgId}/deals/${id}`, {
      params: {
        $filters: options ? JSON.stringify(options) : undefined,
      },
      ...config,
    });

  deleteDeal = (
    orgId: Organization["id"],
    id: Deal["id"],
    config?: RequestOptions
  ): Promise<void> => this.delete(`/organizations/${orgId}/deals/${id}`, config);

  changePassword = (
    password: string,
    currentPassword: string,
    config?: RequestOptions
  ): Promise<void> => this.put("/users/me/password", { currentPassword, password }, config);

  createFile = (
    orgId: Organization["id"],
    file: Blob | File,
    isPublic: boolean = false,
    entityType?: EntityType | undefined,
    entityId?: AnyEntityId | undefined,
    config?: RequestOptions
  ): Promise<RawFile> => {
    const endpoint = `/organizations/${orgId}${
      entityType && entityId ? `/${entityType}/${entityId}` : ""
    }/files`;
    const formData = new FormData();
    if (isPublic) {
      formData.append("isPublic", true.toString());
    }
    return this.uploadFileAsFormData(endpoint, file, formData, config);
  };

  fetchFilePreview = (
    orgId: Organization["id"],
    file: File,
    config?: RequestOptions
  ): Promise<FilePreview> => {
    const formData = new FormData();
    formData.append("file", file);
    return this.post(`/organizations/${orgId}/imports/preview`, formData, config);
  };

  fetchFile = (
    orgId: Organization["id"],
    fileId: RawFile["id"],
    download: boolean,
    preview: boolean,
    entityType?: EntityType,
    entityId?: AnyEntityId,
    config?: RequestOptions
  ): Promise<Blob | RawFile> => {
    const fileUrl = getFilesEndpoint(orgId, fileId, entityType, entityId, download, preview);
    return this.get(fileUrl, config);
  };

  fetchEntityFiles = (
    orgId: Organization["id"],
    entityType: EntityTypeSupportingFiles,
    entityId: EntitiesSupportingFiles["id"],
    { $columns, $filters, $limit, $offset, $order }: Partial<PlatformListRequest> = {},
    config?: RequestOptions
  ): Promise<SimpleListResponse<RawFile>> => {
    const options = {
      params: {
        $columns: $columns ? $columns.join(",") : undefined,
        $filters: $filters ? JSON.stringify($filters) : undefined,
        $limit,
        $offset,
        $order,
      },
      ...config,
    };
    if (entityType === EntityType.COMPANY) {
      return this.get(`/organizations/${orgId}/accounts/${entityId}/files`, options);
    } else if (entityType === EntityType.PERSON) {
      return this.get(`/organizations/${orgId}/contacts/${entityId}/files`, options);
    } else if (entityType === EntityType.DEAL) {
      return this.get(`/organizations/${orgId}/deals/${entityId}/files`, options);
    } else if (entityType === EntityType.ACTIVITY) {
      return this.get(`/organizations/${orgId}/crm-activities/${entityId}/files`, options);
    } else {
      invariant(false, `Unsupported entity type in fetchEntityFiles: ${entityType}`);
    }
  };

  deleteEntityFile = (
    orgId: Organization["id"],
    entityType: EntityTypeSupportingFiles,
    entityId: EntitiesSupportingFiles["id"],
    fileId: RawFile["id"],
    config?: RequestOptions
  ): Promise<void> => {
    if (entityType === EntityType.COMPANY) {
      return this.delete(`/organizations/${orgId}/accounts/${entityId}/files/${fileId}`, config);
    } else if (entityType === EntityType.PERSON) {
      return this.delete(`/organizations/${orgId}/contacts/${entityId}/files/${fileId}`, config);
    } else if (entityType === EntityType.DEAL) {
      return this.delete(`/organizations/${orgId}/deals/${entityId}/files/${fileId}`, config);
    } else if (entityType === EntityType.ACTIVITY) {
      return this.delete(
        `/organizations/${orgId}/crm-activities/${entityId}/files/${fileId}`,
        config
      );
    } else {
      invariant(false, `Unsupported entity type in deleteEntityFiles: ${entityType}`);
    }
  };

  requestPasswordReset = (username: string, config?: RequestOptions): Promise<void> =>
    this.post("/request-password-reset", { username }, config);

  resetPassword = (
    code: string,
    password: string,
    username: string,
    config?: RequestOptions
  ): Promise<void> => this.post("/reset-password", { code, password, username }, config);

  fetchSsoLoginRedirectUri = (username: string, config?: RequestOptions): Promise<string> =>
    this.post("/sso/login", { username }, config);

  search = (
    orgId: Organization["id"],
    searchText: string,
    entityTypes?: string[]
  ): Promise<SimpleListResponse<TextSearchResult>> => {
    if (!searchText.trim().length) {
      return Promise.resolve({ count: 0, data: [], limit: 0, offset: 0, total: 0 });
    }

    const filters = { $q: searchText, entityType: entityTypes };
    return this.get(`/organizations/${orgId}/full-search`, {
      params: { $filters: JSON.stringify(filters) },
    });
  };

  userExists = async (username: string, config?: RequestOptions): Promise<boolean> => {
    const response = await this.post("/users/exists", { username }, config);
    return response.userExists;
  };

  createNote = async (
    orgId: Organization["id"],
    entityType: EntityTypeSupportingNotes,
    entityId: AnyEntityId,
    note: string,
    config?: RequestOptions
  ): Promise<Note> => {
    if (entityType === EntityType.COMPANY) {
      return this.post(`/organizations/${orgId}/accounts/${entityId}/notes`, { note }, config);
    } else if (entityType === EntityType.PERSON) {
      return this.post(`/organizations/${orgId}/contacts/${entityId}/notes`, { note }, config);
    } else if (entityType === EntityType.DEAL) {
      return this.post(`/organizations/${orgId}/deals/${entityId}/notes`, { note }, config);
    } else {
      invariant(false, `Unsupported entity type in createNote: ${entityType}`);
    }
  };

  updateNote = async (
    entityType: EntityTypeSupportingNotes,
    entityId: AnyEntityId,
    note: Note,
    config?: RequestOptions
  ): Promise<Note> => {
    if (entityType === EntityType.COMPANY) {
      return this.put(`/accounts/${entityId}/notes/${note.id}`, note, config);
    } else if (entityType === EntityType.PERSON) {
      return this.put(`/contacts/${entityId}/notes/${note.id}`, note, config);
    } else if (entityType === EntityType.DEAL) {
      return this.put(`/deals/${entityId}/notes/${note.id}`, note, config);
    } else {
      invariant(false, `Unsupported entity type in updateNote: ${entityType}`);
    }
  };

  deleteNote = async (
    entityType: EntityTypeSupportingNotes,
    entityId: AnyEntityId,
    note: Note,
    config?: RequestOptions
  ): Promise<void> => {
    if (entityType === EntityType.COMPANY) {
      return this.delete(`/accounts/${entityId}/notes/${note.id}`, config);
    } else if (entityType === EntityType.PERSON) {
      return this.delete(`/contacts/${entityId}/notes/${note.id}`, config);
    } else if (entityType === EntityType.DEAL) {
      return this.delete(`/deals/${entityId}/notes/${note.id}`, config);
    } else {
      invariant(false, `Unsupported entity type in deleteNote: ${entityType}`);
    }
  };

  fetchActivity = async (
    orgId: Organization["id"],
    activityId: Activity["id"],
    options?: {
      includeAccessStatus?: boolean;
      includeAccountSharedActivities?: boolean;
      includeContactSharedActivities?: boolean;
      includeCustomFields?: boolean;
      includeDealSharedActivities?: boolean;
      includeFiles?: boolean;
      includeUsersWithAccess?: boolean;
    },
    config?: RequestOptions
  ): Promise<Activity> =>
    this.get(`/organizations/${orgId}/crm-activities/${activityId}`, {
      params: {
        $filters: options ? JSON.stringify(options) : undefined,
      },
      ...config,
    });

  createActivity = async (
    orgId: Organization["id"],
    layoutId: FormLayout["id"],
    payload: ActivityPayload,
    options?: {
      includeAccessStatus?: boolean;
    },
    config?: RequestOptions
  ): Promise<Activity> => {
    const $filters = { includeAccessStatus: true, ...(options ?? {}) };
    return this.post(`/organizations/${orgId}/crm-activities`, payload, {
      params: {
        $filters: JSON.stringify($filters),
        layoutId,
      },
      ...config,
    });
  };

  updateActivity = async (
    orgId: Organization["id"],
    layoutId: FormLayout["id"] | undefined,
    payload: Partial<ActivityPayload> & Identified,
    options?: {
      includeAccessStatus?: boolean;
      includeAccountSharedActivities?: boolean;
      includeContactSharedActivities?: boolean;
      includeCustomFields?: boolean;
      includeDealSharedActivities?: boolean;
      includeFiles?: boolean;
      updateRecurring?: RecurringActionType;
    },
    config?: RequestOptions
  ): Promise<Activity> => {
    if (!layoutId) {
      loggingService.debug(`Layout ID should be provided in updateActivity request`, { payload });
    }
    const $filters = { includeAccessStatus: true, ...(options ?? {}) };
    await this.put(`/organizations/${orgId}/crm-activities/${payload.id}`, payload, {
      ...config,
      params: {
        ...config?.params,
        ignoreLayout: layoutId ? undefined : true,
        layoutId,
        $filters: JSON.stringify($filters),
      },
    });
    return this.fetchActivity(orgId, payload.id, $filters);
  };

  deleteActivity = async (
    orgId: Organization["id"],
    activityId: Activity["id"],
    deleteRecurring?: RecurringActionType,
    config?: RequestOptions
  ): Promise<void> =>
    this.delete(`/organizations/${orgId}/crm-activities/${activityId}`, {
      params: {
        $filters: deleteRecurring && JSON.stringify({ deleteRecurring }),
      },
      ...config,
    });

  createDeal = async (
    orgId: Organization["id"],
    layoutId: FormLayout["id"],
    payload: CreateDealPayload,
    options?: {
      includeAccessStatus?: boolean;
    },
    config?: RequestOptions
  ): Promise<Report> => {
    const $filters = { includeAccessStatus: true, ...(options ?? {}) };
    return this.post(`/organizations/${orgId}/deals`, payload, {
      params: {
        $filters: JSON.stringify($filters),
        layoutId,
      },
      ...config,
    });
  };

  updateDeal = async (
    orgId: Organization["id"],
    layoutId: FormLayout["id"] | undefined,
    payload: OnlyRequiredFields<DealPayload, "id">,
    options?: {
      includeAccessStatus?: boolean;
      includeCustomFields?: boolean;
      includeGroups?: boolean;
      includeNotes?: boolean;
      includeUsersWithAccess?: boolean;
    },
    config?: RequestOptions
  ): Promise<Deal> => {
    if (!layoutId) {
      loggingService.debug(`Layout ID should be provided in updateDeal request`, { payload });
    }
    await this.put(`/organizations/${orgId}/deals/${payload.id}`, omit(payload, "files"), {
      ...config,
      params: { ...config?.params, ignoreLayout: layoutId ? undefined : true, layoutId },
    });
    const getOptions = { includeAccessStatus: true, ...(options ?? {}) };
    return this.fetchDeal(orgId, payload.id, getOptions);
  };

  fetchDealLossReasons = async (
    orgId: Organization["id"],
    config?: RequestOptions
  ): Promise<ListResponse<DealLossReason>> =>
    this.get(`/organizations/${orgId}/deal-loss-reasons`, config);

  createDealLossReason = async (
    orgId: Organization["id"],
    { displayOrder, funnelId, name }: CreateDealLossReasonPayload,
    config?: RequestOptions
  ): Promise<DealLossReason> =>
    this.post(
      `/organizations/${orgId}/deal-loss-reasons`,
      { displayOrder, funnelId, name },
      config
    );

  deleteDealLossReason = async (
    orgId: Organization["id"],
    dealLossReasonId: DealLossReason["id"],
    config?: RequestOptions
  ): Promise<void> =>
    this.delete(`/organizations/${orgId}/deal-loss-reasons/${dealLossReasonId}`, config);

  updateDealLossReason = async (
    orgId: Organization["id"],
    payload: DealLossReason,
    config?: RequestOptions
  ): Promise<DealLossReason> =>
    this.put(`/organizations/${orgId}/deal-loss-reasons/${payload.id}`, payload, config);

  addToGroup = async (
    orgId: Organization["id"],
    groupId: Group["id"],
    entityType: EntityType,
    entityIds: AnyEntityId[],
    config?: RequestOptions
  ): Promise<void> => {
    if (entityType === EntityType.COMPANY) {
      await this.post(
        `/organizations/${orgId}/account-groups/${groupId}/accounts/bulk-create`,
        {
          accountGroups: entityIds.map((accountId) => ({ accountId })),
        },
        config
      );
    } else if (entityType === EntityType.PERSON) {
      await this.post(
        `/organizations/${orgId}/contact-groups/${groupId}/contacts/bulk-create`,
        {
          contactGroups: entityIds.map((contactId) => ({ contactId })),
        },
        config
      );
    } else if (entityType === EntityType.DEAL) {
      await this.post(
        `/organizations/${orgId}/deal-groups/${groupId}/deals/bulk-create`,
        {
          dealGroups: entityIds.map((dealId) => ({ dealId })),
        },
        config
      );
    } else {
      invariant(false, `Unsupported entity type in addToGroup: ${entityType}`);
    }
  };

  deleteFromGroup = async (
    orgId: Organization["id"],
    groupId: Group["id"],
    entityType: EntityTypeSupportingGroups,
    entityIds: AnyEntityId[],
    config?: RequestOptions
  ): Promise<void> => {
    if (entityType === EntityType.COMPANY) {
      await this.delete(`/organizations/${orgId}/account-groups/${groupId}/accounts/bulk-delete`, {
        data: {
          accountGroups: entityIds.map((accountId) => ({ accountId })),
        },
        ...config,
      });
    } else if (entityType === EntityType.PERSON) {
      await this.delete(`/organizations/${orgId}/contact-groups/${groupId}/contacts/bulk-delete`, {
        data: {
          contactGroups: entityIds.map((contactId) => ({ contactId })),
        },
        ...config,
      });
    } else if (entityType === EntityType.DEAL) {
      await this.delete(`/organizations/${orgId}/deal-groups/${groupId}/deals/bulk-delete`, {
        data: {
          dealGroups: entityIds.map((dealId) => ({ dealId })),
        },
        ...config,
      });
    } else {
      invariant(false, `Unsupported entity type in deleteFromGroup: ${entityType}`);
    }
  };

  updateEntities = async (
    orgId: Organization["id"],
    entityType: EntityType,
    entities: Partial<AnyEntity>[],
    config?: RequestOptions
  ): Promise<void> => {
    if (entityType === EntityType.COMPANY) {
      await this.put(
        `/organizations/${orgId}/accounts/bulk-update`,
        { accountIds: entities },
        config
      );
    } else if (entityType === EntityType.PERSON) {
      await this.put(
        `/organizations/${orgId}/contacts/bulk-update`,
        { contactIds: entities },
        config
      );
    } else if (entityType === EntityType.DEAL) {
      await this.put(`/organizations/${orgId}/deals/bulk-update`, { dealIds: entities }, config);
    } else if (entityType === EntityType.ACTIVITY) {
      await this.put(
        `/organizations/${orgId}/crm-activities/bulk-update`,
        {
          crmActivityIds: (entities as Activity[]).map(
            ({ assignee, crmActivityType, ...rest }) => ({
              ...rest,
              assigneeId: assignee?.id,
              crmActivityType: { id: crmActivityType?.id },
            })
          ),
        },
        config
      );
    } else {
      invariant(false, `Unsupported entity type in bulk update: ${entityType}`);
    }
  };

  deleteEntities = async (
    orgId: Organization["id"],
    entityType: EntityType,
    entityIds: AnyEntityId[],
    config?: RequestOptions
  ): Promise<void> => {
    const ids = entityIds.map((id) => ({ id })); // <- platform expects id list to have this form
    if (entityType === EntityType.COMPANY) {
      await this.delete(`/organizations/${orgId}/accounts/bulk-delete`, {
        data: { accountIds: ids },
        ...config,
      });
    } else if (entityType === EntityType.PERSON) {
      await this.delete(`/organizations/${orgId}/contacts/bulk-delete`, {
        data: { contactIds: ids },
        ...config,
      });
    } else if (entityType === EntityType.DEAL) {
      await this.delete(`/organizations/${orgId}/deals/bulk-delete`, {
        data: { dealIds: ids },
        ...config,
      });
    } else if (entityType === EntityType.ACTIVITY) {
      await this.delete(`/organizations/${orgId}/crm-activities/bulk-delete`, {
        data: { crmActivityIds: ids },
        ...config,
      });
    } else {
      invariant(false, `Unsupported entity type in bulk delete: ${entityType}`);
    }
  };

  createGroup = (
    orgId: Organization["id"],
    entityType: EntityTypeSupportingGroups,
    name: string,
    crmActivityTypeId: ActivityType["id"][],
    color?: AnyColor,
    cadenceInterval?: number,
    options?: {
      includeAccessStatus?: boolean;
    },
    config?: RequestOptions
  ): Promise<Group> => {
    const $filters = { includeAccessStatus: true, ...(options ?? {}) };
    return this.post(
      `/organizations/${orgId}/${entityTypeToGroupsEndpoint[entityType]}`,
      { cadenceInterval, color: color || null, crmActivityTypeId, name },
      {
        params: {
          $filters: JSON.stringify($filters),
        },
        ...config,
      }
    );
  };

  updateGroup = (
    orgId: Organization["id"],
    entityType: EntityTypeSupportingGroups,
    group: Group,
    options?: {
      includeAccessStatus?: boolean;
    },
    config?: RequestOptions
  ): Promise<Group> => {
    const $filters = { includeAccessStatus: true, ...(options ?? {}) };
    return this.put(
      `/organizations/${orgId}/${entityTypeToGroupsEndpoint[entityType]}/${group.id}`,
      group,
      {
        params: {
          $filters: JSON.stringify($filters),
        },
        ...config,
      }
    );
  };

  deleteGroup = (
    orgId: Organization["id"],
    entityType: EntityTypeSupportingGroups,
    groupId: Group["id"],
    config?: RequestOptions
  ): Promise<void> =>
    this.delete(
      `/organizations/${orgId}/${entityTypeToGroupsEndpoint[entityType]}/${groupId}`,
      config
    );

  fetchStatistics = (
    orgId: Organization["id"],
    filters: Partial<{
      date: PlatformSimpleCondition;
      funnel: string;
      summary: "all" | "date" | "funnel" | "user";
      teamId: { $in: Array<Team["id"]> };
      userId: { $in: Array<User["id"]> } | User["id"];
    }>,
    config?: RequestOptions
  ): Promise<ListResponse<Statistics>> =>
    this.get(`/organizations/${orgId}/stats`, {
      params: {
        $filters: filters ? JSON.stringify(filters) : undefined,
      },
      ...config,
    });

  createCompaniesInBulk = async (
    orgId: Organization["id"],
    companies: Array<Partial<CompanyWithLeadId>>,
    config?: RequestOptions
  ): Promise<Array<Company[]>> =>
    this.post(`/organizations/${orgId}/accounts/bulk-create`, { accountIds: companies }, config);

  createPeopleInBulk = async (
    orgId: Organization["id"],
    people: Array<Partial<PersonWithLeadId>>,
    config?: RequestOptions
  ): Promise<Array<Person[]>> =>
    this.post(`/organizations/${orgId}/contacts/bulk-create`, { contactIds: people }, config);

  upsertCompaniesInBulk = async (
    orgId: Organization["id"],
    companies: Array<Partial<CompanyWithLeadId>>,
    config?: RequestOptions
  ): Promise<Array<Company[]>> =>
    this.put(`/organizations/${orgId}/accounts/bulk`, companies, config);

  upsertPeopleInBulk = async (
    orgId: Organization["id"],
    people: Array<Partial<PersonWithLeadId>>,
    config?: RequestOptions
  ): Promise<Array<Person[]>> => this.put(`/organizations/${orgId}/contacts/bulk`, people, config);

  setCompanyParent = (
    orgId: Organization["id"],
    companyId: Company["id"],
    parentId: Company["id"] | null
  ): Promise<Company> =>
    this.put(`/organizations/${orgId}/accounts/${companyId}`, {
      id: companyId,
      parentAccount: { id: parentId },
    });

  signup = (
    token: string,
    fullName: string,
    username: string,
    phone: string,
    password: string,
    organizationName: string,
    marketingEnabled: boolean,
    referralSource: string,
    orgMetaData: Organization["metaData"],
    userMetaData: User["metaData"],
    config?: RequestOptions
  ): Promise<{ organization: Organization; user: UserRef }> =>
    this.post(
      "/signup",
      {
        client: "Web",
        fullName,
        marketingEnabled,
        metaData: userMetaData,
        organizationName,
        orgMetaData,
        password,
        phone,
        referralSource,
        token,
        type: orgMetaData?.numFieldReps === "1" ? "individual" : "team",
        username,
      },
      config
    );

  acceptInvitation = (
    token: string,
    invitationCode: string,
    fullName: string,
    username: string,
    phone: string,
    password: string,
    marketingEnabled: boolean,
    userMetaData: User["metaData"],
    config?: RequestOptions
  ): Promise<{ organization: Organization; user: UserRef }> =>
    this.post(
      "/accept-invitation",
      {
        fullName,
        invitationCode,
        marketingEnabled,
        metaData: userMetaData,
        password,
        phone,
        token,
        username,
      },
      config
    );

  fetchNotes = (
    entityType: EntityTypeSupportingNotes,
    entityId: AnyEntityId,
    { $columns, $filters, $limit, $offset, $order }: Partial<PlatformListRequest> = {},
    config?: RequestOptions
  ): Promise<SimpleListResponse<Note>> =>
    this.get(`/${entityType}/${entityId}/notes`, {
      params: {
        $columns: $columns ? $columns.join(",") : undefined,
        $filters: $filters ? JSON.stringify($filters) : undefined,
        $limit,
        $offset,
        $order,
      },
      ...config,
    });

  fetchPlans = (config?: RequestOptions): Promise<ListResponse<Plan>> => this.get(`/plans`, config);

  upgradeSubscription = (
    orgId: Organization["id"],
    token: string,
    planId: Plan["id"],
    licenses: number,
    config?: RequestOptions
  ): Promise<void> =>
    this.post(
      `organizations/${orgId}/upgrade`,
      { cardToken: token, plan: { id: planId }, quantity: licenses },
      config
    );

  startImport = (
    orgId: Organization["id"],
    payload: ImportPayload,
    config?: RequestOptions
  ): Promise<Import> => this.post(`/organizations/${orgId}/imports`, payload, config);

  fetchImport = (
    orgId: Organization["id"],
    importId: Import["id"],
    config?: RequestOptions
  ): Promise<Import> => this.get(`/organizations/${orgId}/imports/${importId}`, config);

  fetchImports = (
    orgId: Organization["id"],
    config?: RequestOptions
  ): Promise<ListResponse<Import>> =>
    this.get(`/organizations/${orgId}/imports?$order=-updatedAt`, config);

  createCompany = async (
    orgId: Organization["id"],
    layoutId: FormLayout["id"],
    payload: CompanyPayload,
    options?: {
      headers?: RequestOptions["headers"];
      includeAccessStatus?: boolean;
    },
    config?: RequestOptions
  ): Promise<Company> => {
    const $filters = { includeAccessStatus: true, ...(options ?? {}) };
    return this.post(`/organizations/${orgId}/accounts`, payload, {
      params: {
        $filters: JSON.stringify($filters),
        layoutId,
      },
      ...config,
    });
  };

  fetchInvitation = (invitationCode: string, config?: RequestOptions): Promise<InvitationInfo> =>
    this.get(`/invitation/${invitationCode}`, config);

  fetchImportConfigs = async (
    orgId: Organization["id"],
    type?: EntityTypesSupportedByImport,
    config?: RequestOptions
  ): Promise<ListResponse<ImportConfig>> => {
    const result = await this.get(`/organizations/${orgId}/import-configs`, {
      params: {
        $filters: JSON.stringify(type ? { type } : {}),
      },
      ...config,
    });
    result.data = result.data.map(parseDatedFields);
    return result;
  };

  createImportConfig = (
    orgId: Organization["id"],
    importConfig: Omit<Partial<ImportConfig>, "id">,
    config?: RequestOptions
  ): Promise<ImportConfig> =>
    this.post(`/organizations/${orgId}/import-configs`, importConfig, config);

  updateImportConfig = (
    orgId: Organization["id"],
    importConfig: ImportConfig,
    config?: RequestOptions
  ): Promise<ImportConfig> =>
    this.put(`/organizations/${orgId}/import-configs/${importConfig.id}`, importConfig, config);

  deleteImportConfig = (
    orgId: Organization["id"],
    importConfigId: ImportConfig["id"],
    config?: RequestOptions
  ): Promise<void> =>
    this.delete(`/organizations/${orgId}/import-configs/${importConfigId}`, config);

  fetchNylasInfo = (config?: RequestOptions): Promise<NylasInfo> => this.get("/nylas/me", config);

  createNylas = (payload: NylasInfoCreatePayload, config?: RequestOptions): Promise<NylasInfo> =>
    this.post("/nylas", payload, config);

  updateNylas = (payload: NylasInfoUpdatePayload, config?: RequestOptions): Promise<NylasInfo> =>
    this.put(`/nylas/${payload.id}`, payload, config);

  deleteNylas = (nylasId: NylasInfo["id"], config?: RequestOptions): Promise<void> =>
    this.delete(`/nylas/${nylasId}`, config);

  fetchCalendars = (nylasId: NylasInfo["id"], config?: RequestOptions): Promise<void> =>
    this.get(`/nylas/${nylasId}/calendars`, config);

  createNylasEvent = (
    nylasId: NylasInfo["id"],
    payload: CreateNylasEventPayload,
    config?: RequestOptions
  ): Promise<unknown> => this.post(`/nylas/${nylasId}/event`, payload, config);

  fetchSavedFilters = async (
    orgId: Organization["id"],
    entityType: EntityType,
    config?: RequestOptions
  ): Promise<SimpleListResponse<PlatformSavedFilter>> =>
    await this.get(`/organizations/${orgId}/saved-filters`, {
      params: { $filters: JSON.stringify({ type: entityType }), $limit: 1000 },
      ...config,
    });

  createSavedFilter = (
    orgId: Organization["id"],
    filter: Omit<PlatformSavedFilter, "createdAt" | "id" | "updatedAt" | "valid">,
    config?: RequestOptions
  ): Promise<PlatformSavedFilter> =>
    this.post(`/organizations/${orgId}/saved-filters`, filter, config);

  updateSavedFilter = (
    orgId: Organization["id"],
    filter: Omit<PlatformSavedFilter, "createdAt" | "updatedAt" | "valid">,
    config?: RequestOptions
  ): Promise<PlatformSavedFilter> =>
    this.put(`/organizations/${orgId}/saved-filters/${filter.id}`, filter, config);

  deleteSavedFilter = (
    orgId: Organization["id"],
    filterId: SavedFilter["id"],
    config?: RequestOptions
  ): Promise<void> => this.delete(`/organizations/${orgId}/saved-filters/${filterId}`, config);

  fetchUserSettings = (
    orgId: Organization["id"],
    userId: User["id"],
    config?: RequestOptions
  ): Promise<Setting> => this.get(`/organizations/${orgId}/users/${userId}/settings`, config);

  updateUserSetting = (
    orgId: Organization["id"],
    userId: User["id"],
    setting: Setting,
    config?: RequestOptions
  ): Promise<Setting> =>
    this.put(`/organizations/${orgId}/users/${userId}/settings/${setting.id}`, setting, config);

  fetchPins = (
    orgId: Organization["id"],
    { $aggs, $columns, $filters, $limit, $offset, $order }: Partial<PlatformListRequest> = {},
    config?: RequestOptions
  ): Promise<AggregatedListResponse<MapEntity, ListResponseAggregation<MapEntity>[]>> =>
    this.post(
      `/organizations/${orgId}/pins`,
      {
        $aggs,
        $columns: $columns ? $columns.join(",") : undefined,
        $filters,
        $limit,
        $offset,
        $order,
      },
      config
    );

  deleteFile = (
    orgId: Organization["id"],
    fileId: RawFile["id"],
    config?: RequestOptions
  ): Promise<void> => this.delete(`organizations/${orgId}/files/${fileId}`, config);

  createFunnel = (
    orgId: Organization["id"],
    funnel: Partial<Omit<Funnel, "id">>,
    config?: RequestOptions
  ): Promise<Funnel> => {
    return this.post(`organizations/${orgId}/funnels`, funnel, config);
  };

  updateFunnel = (
    orgId: Organization["id"],
    funnel: Funnel,
    config?: RequestOptions
  ): Promise<Funnel> => this.put(`organizations/${orgId}/funnels/${funnel.id}`, funnel, config);

  deleteFunnel = (
    orgId: Organization["id"],
    funnelId: Funnel["id"],
    config?: RequestOptions
  ): Promise<void> => this.delete(`organizations/${orgId}/funnels/${funnelId}`, config);

  createStage = (
    orgId: Organization["id"],
    funnelId: Funnel["id"],
    stage: Partial<Omit<Stage, "id">>,
    config?: RequestOptions
  ): Promise<Stage> => {
    return this.post(`organizations/${orgId}/funnels/${funnelId}/stages`, stage, config);
  };

  updateStage = (
    orgId: Organization["id"],
    funnelId: Funnel["id"],
    stage: Stage,
    config?: RequestOptions
  ): Promise<Stage> =>
    this.put(`organizations/${orgId}/funnels/${funnelId}/stages/${stage.id}`, stage, config);

  deleteStage = (
    orgId: Organization["id"],
    funnelId: Funnel["id"],
    stageId: Stage["id"],
    config?: RequestOptions
  ): Promise<void> =>
    this.delete(`organizations/${orgId}/funnels/${funnelId}/stages/${stageId}`, config);

  bulkCreateGroupShare = (
    orgId: Organization["id"],
    sharedGroups: Array<{ groupId: Group["id"]; userId: User["id"] }>,
    config?: RequestOptions
  ): Promise<string> =>
    this.post(
      `organizations/${orgId}/shared-groups/bulk-create`,
      { sharedGroups: sharedGroups },
      config
    );

  bulkDeleteGroupShare = (
    orgId: Organization["id"],
    sharedGroups: Array<{ groupId: Group["id"]; userId: User["id"] }>,
    config?: RequestOptions
  ): Promise<string> =>
    this.delete(`organizations/${orgId}/shared-groups/bulk-delete`, {
      data: { sharedGroups: sharedGroups },
      ...config,
    });

  getSandboxAccessToken = (
    orgId: Organization["id"],
    config?: RequestOptions
  ): Promise<SandboxAccessTokenData> => this.get(`organizations/${orgId}/sandbox-access`, config);

  createSandbox = (
    orgId: Organization["id"],
    name: string,
    config?: RequestOptions
  ): Promise<Sandbox> =>
    this.post(`organizations/${orgId}/org-clone`, { cloneConfig: { name } }, config);

  updateSandbox = (
    orgId: Organization["id"],
    sandboxConfig: SandboxConfig,
    config?: RequestOptions
  ): Promise<Sandbox> =>
    this.put(`organizations/${orgId}/org-clone`, { cloneConfig: sandboxConfig }, config);

  deleteSandbox = (orgId: Organization["id"], config?: RequestOptions): Promise<void> =>
    this.delete(`organizations/${orgId}/org-clone`, config);

  fetchSandboxes = (
    orgId: Organization["id"],
    config?: RequestOptions
  ): Promise<ListResponse<Sandbox>> => this.get(`organizations/${orgId}/org-clone`, config);

  getEntityDuplicates = (
    orgId: Organization["id"],
    entityType: EntityTypeSupportingDataMerging,
    { $columns, $filters, $limit, $offset, $order }: Partial<PlatformListRequest> = {},
    config?: RequestOptions
  ): Promise<ListResponse<DuplicatePair>> =>
    this.get(`/organizations/${orgId}/${entityTypeToDuplicates[entityType]}`, {
      params: {
        $columns: $columns ? $columns.join(",") : undefined,
        $filters: $filters ? JSON.stringify($filters) : undefined,
        $limit,
        $offset,
        $order,
      },
      ...config,
    });

  updateDuplicatePair = (
    orgId: Organization["id"],
    entityType: EntityTypeSupportingDataMerging,
    duplicate: DuplicatePair,
    config?: RequestOptions
  ): Promise<ListResponse<DuplicatePair>> =>
    this.put(
      `/organizations/${orgId}/${entityTypeToDuplicates[entityType]}/${duplicate.id}`,
      duplicate,
      config
    );

  mergeDuplicates = (
    orgId: Organization["id"],
    entityType: EntityTypeSupportingDataMerging,
    duplicateId: DuplicatePair["id"],
    primaryId: DuplicateEntity["id"],
    secondaryId: DuplicateEntity["id"],
    config?: RequestOptions
  ): Promise<void> =>
    this.post(
      `/organizations/${orgId}/${mergeEntity[entityType]}`,
      {
        id: duplicateId,
        primary: { id: primaryId },
        secondary: { id: secondaryId },
      },
      config
    );

  getDuplicateRun = (orgId: Organization["id"], config?: RequestOptions): Promise<DuplicateRun> =>
    this.get(`/organizations/${orgId}/dupe-run`, config);

  recalculateDuplicates = (
    orgId: Organization["id"],
    config?: RequestOptions
  ): Promise<DuplicateRun> => this.post(`/organizations/${orgId}/dupe-run`, {}, config);

  geocodeAddress = (
    orgId: Organization["id"],
    address: Pick<Located, "address" | "city" | "country" | "postalCode" | "region">,
    config?: RequestOptions
  ): Promise<GeocodeResult> => this.post(`organizations/${orgId}/geocode/address`, address, config);

  reverseGeocodeAddress = (
    orgId: Organization["id"],
    point: GeoPoint,
    config?: RequestOptions
  ): Promise<GeocodeResult> => this.post(`organizations/${orgId}/geocode/latlon`, point, config);

  fetchTimelineEvents = async (
    orgId: Organization["id"],
    { $filters, $limit, $offset, $order }: Partial<PlatformListRequest> = {},
    config?: RequestOptions
  ): Promise<ListResponse<TimelineEvent>> => {
    const response: ListResponse<RawTimelineEvent> = await this.get(
      `/organizations/${orgId}/timeline`,
      {
        params: {
          $filters: $filters ? JSON.stringify($filters) : undefined,
          $limit,
          $offset,
          $order,
        },
        ...config,
      }
    );

    const actionsSet = new Set();
    const rawActionsSet = new Set();

    const validActions = new Set([
      TimelineAction.RECORD_ADDED,
      TimelineAction.RECORD_UPDATED,
      TimelineAction.NOTE_ADDED,
      TimelineAction.MENTION,
      TimelineAction.ACTIVITY_COMPLETED,
      TimelineAction.ACTIVITY_ASSIGNED,
      TimelineAction.ROUTE_CREATED,
      TimelineAction.FILE_ADDED,
    ]);

    const updatedData: BaseTimelineEvent[] = [];
    response.data.forEach((item) => {
      rawActionsSet.add(item.action);
      const actions = item.action.split(/\s*,\s*/) as TimelineAction[];
      actions.forEach((action) => {
        actionsSet.add(action);
        if (validActions.has(action)) {
          if (
            actions.includes(TimelineAction.ACTIVITY_COMPLETED) &&
            actions.includes(TimelineAction.RECORD_UPDATED) &&
            action !== TimelineAction.ACTIVITY_COMPLETED
          ) {
            return;
          }
          updatedData.push({ ...item, action, originalAction: item.action });
        }
      });
    });
    (response as ListResponse<BaseTimelineEvent>).data = updatedData;

    console.log("!!! unique actions:", Array.from(actionsSet), Array.from(rawActionsSet));

    return response as unknown as ListResponse<TimelineEvent>;
  };

  fetchNotifications = (
    orgId: Organization["id"],
    { $filters, $limit, $offset, $order }: Partial<PlatformListRequest> = {},
    config?: RequestOptions
  ): Promise<NotificationListResponse<MmcNotification>> =>
    this.get(`/organizations/${orgId}/notifications`, {
      params: {
        $filters: $filters ? JSON.stringify($filters) : undefined,
        $limit,
        $offset,
        $order,
      },
      ...config,
    });

  getNotification = (
    orgId: Organization["id"],
    id: MmcNotification["id"],
    config?: RequestOptions
  ): Promise<MmcNotification> => this.get(`/organizations/${orgId}/notifications/${id}`, config);

  updateNotifications = (
    orgId: Organization["id"],
    ids: MmcNotification["id"][],
    payload: Partial<MmcNotification>,
    { $columns, $filters, $limit, $offset, $order }: Partial<PlatformListRequest> = {},
    config?: RequestOptions
  ): Promise<NotificationListResponse<MmcNotification>> =>
    this.put(
      `/organizations/${orgId}/notifications/bulk-update`,
      { id: ids, ...payload },
      {
        params: {
          $filters: $filters ? JSON.stringify($filters) : undefined,
          $limit,
          $offset,
          $order,
        },
        ...config,
      }
    );

  fetchMapPins = (
    orgId: Organization["id"],
    { $columns, $filters, $limit, $offset, $order }: Partial<PlatformMapRequest> = {},
    config?: RequestOptions
  ): Promise<ListResponse<MapEntry>> =>
    this.post(
      `/organizations/${orgId}/pins`,
      {
        $columns: $columns ? $columns.join(",") : undefined,
        $filters,
        $limit,
        $offset,
        $order,
      },
      config
    );

  fetchLeads = (
    orgId: Organization["id"],
    { $filters, $limit, $offset }: Partial<PlatformMapRequest> = {},
    config?: RequestOptions
  ): Promise<ListResponse<Lead>> =>
    this.get(`/organizations/${orgId}/google-leads`, {
      params: {
        $filters: $filters ? JSON.stringify($filters) : undefined,
        $limit,
        $offset,
      },
      ...config,
    });

  updateHiddenLeads = (
    orgId: Organization["id"],
    payload: Lead["place_id"][],
    config?: RequestOptions
  ): Promise<ListResponse<Lead["place_id"]>> =>
    this.put(`/organizations/${orgId}/google-leads/blacklist`, payload, {
      ...config,
    });

  deleteHiddenLeads = (
    orgId: Organization["id"],
    payload: Lead["place_id"][],
    config?: RequestOptions
  ): Promise<ListResponse<Lead["place_id"]>> =>
    this.delete(`/organizations/${orgId}/google-leads/blacklist`, {
      ...config,
      data: payload,
    });

  fetchLeadFinderLimits = (
    orgId: Organization["id"],
    config?: RequestOptions
  ): Promise<LeadFinderLimit> => this.get(`/organizations/${orgId}/google-leads-quota`, config);

  fetchTerritories = async (
    orgId: Organization["id"],
    { $columns, $filters, $limit, $offset, $order }: Partial<PlatformListRequest> = {},
    config?: RequestOptions
  ): Promise<ListResponse<Territory>> => {
    const territoryList: ListResponse<Territory> = await this.get(
      `/organizations/${orgId}/territories`,
      {
        params: {
          $columns: $columns ? $columns.join(",") : undefined,
          $filters: $filters ? JSON.stringify($filters) : undefined,
          $limit,
          $offset,
          $order,
        },
        ...config,
      }
    );

    territoryList.data = territoryList.data
      .filter(
        (territory) =>
          (territory.territoryDetail.type === "points" && territory.territoryDetail.points) ||
          territory.territoryDetail.type !== "points" /*&&
            territory.territoryDetail.shapeFile?.publicURI &&
            territory.territoryDetail.shapeFile?.publicURI.endsWith("shape.bjson")*/
      )
      .map(removeExtraCoordinate);

    const territoriesWithFiles = territoryList.data.filter(
      (territory) => territory.territoryDetail.shapeFile?.publicURI
    );

    const files = await Promise.all(
      territoriesWithFiles.map(async (territory) => {
        const response = await fetch(territory.territoryDetail.shapeFile!.publicURI!, {});
        return response.arrayBuffer();
      })
    );
    territoryList.data.forEach((territory) => {
      const index = territoriesWithFiles.findIndex(({ id }) => id === territory.id);
      if (index >= 0) {
        try {
          territory.territoryDetail.shape = geometryFromBinary(files[index]);
        } catch (error) {
          loggingService.error(`Failed to decode territory (id=${territory.id}) shape file`, {
            error,
          });
        }
      }
    });

    return territoryList;
  };

  fetchTerritory = async (
    orgId: Organization["id"],
    territoryId: Territory["id"],
    options?: {
      includeAccessStatus?: boolean;
    },
    config?: RequestOptions
  ): Promise<Territory> => {
    const $filters = { includeAccessStatus: true, ...(options ?? {}) };
    let territory: Territory = await this.get(
      `/organizations/${orgId}/territories/${territoryId}`,
      {
        params: {
          $filters: JSON.stringify($filters),
        },
        ...config,
      }
    );

    if (territory.territoryDetail.type === "points") {
      territory = removeExtraCoordinate(territory);
    } else if (territory.territoryDetail.shapeFile?.publicURI) {
      const response = await fetch(territory.territoryDetail.shapeFile!.publicURI!, {});
      const shapeArrayBuffer = await response.arrayBuffer();
      territory.territoryDetail.shape = geometryFromBinary(shapeArrayBuffer);
    }

    return territory;
  };

  createTerritory = (
    orgId: Organization["id"],
    territory: {
      adm1: string[];
      adm2: string[];
      adm3: string[];
      color?: AnyColor;
      coordinates: LongLat[][];
      hidden?: boolean;
      name: string;
      opacity: number;
      type?: "points" | AreaType;
      userId: User["id"];
    },
    shape?: GeoMultiPolygon | GeoPolygon,
    options?: {
      includeAccessStatus?: boolean;
    },
    config?: RequestOptions
  ): Promise<Territory> => {
    const $filters = { includeAccessStatus: true, ...(options ?? {}) };

    const payload = {
      color: territory.color || Color.BLACK,
      name: territory.name,
      postalCodes: [],
      territoryDetail:
        territory.type !== "points"
          ? {
              adm1: territory.adm1,
              adm2: territory.adm2,
              adm3: territory.adm3,
              hidden: territory.hidden ?? false,
              opacity: territory.opacity,
              type: territory.type,
            }
          : addExtraCoordinateToTerritoryDetail({
              hidden: territory.hidden ?? false,
              opacity: territory.opacity,
              points: { coordinates: territory.coordinates, type: "Polygon" },
              type: territory.type,
            }),
      user: { id: territory.userId },
    };

    const formData = new FormData();
    formData.append("payload", JSON.stringify(payload));
    if (shape) {
      formData.append(
        "shapeFile",
        new Blob([geometryToBinary(shape)], { type: "application/x-binary" }),
        "shape.bjson"
      );
    }

    return this.post(`/organizations/${orgId}/territories`, formData, {
      params: {
        $filters: JSON.stringify($filters),
      },
      ...config,
    });
  };

  updateTerritory = (
    orgId: Organization["id"],
    territory: Territory,
    shape?: GeoMultiPolygon | GeoPolygon,
    options?: {
      includeAccessStatus?: boolean;
    },
    config?: RequestOptions
  ): Promise<Territory> => {
    const $filters = { includeAccessStatus: true, ...(options ?? {}) };

    let payload = addExtraCoordinate(territory);
    payload = { ...payload, territoryDetail: { ...payload.territoryDetail, shape: undefined } }; // do not send Shape
    const formData = new FormData();
    formData.append("payload", JSON.stringify(payload));
    if (shape) {
      formData.append(
        "shapeFile",
        new Blob([geometryToBinary(shape)], { type: "application/x-binary" }),
        "shape.bjson"
      );
    }

    return this.put(`/organizations/${orgId}/territories/${territory.id}`, formData, {
      params: {
        $filters: JSON.stringify($filters),
      },
      ...config,
    });
  };

  deleteTerritory = (
    orgId: Organization["id"],
    territoryId: Territory["id"],
    config?: RequestOptions
  ): Promise<void> => this.delete(`/organizations/${orgId}/territories/${territoryId}`, config);

  fetchEntityHistory = (
    orgId: Organization["id"],
    entityType: EntityTypeSupportingHistory,
    entityId: EntitiesSupportingHistory["id"],
    { $filters, $limit, $offset, $order }: Partial<Omit<PlatformListRequest, "$columns">>,
    config?: RequestOptions
  ): Promise<ListResponse<EntityHistoryRow>> =>
    this.get(
      `/organizations/${orgId}/${entityTypeToHistoryEndpointEndpoint[entityType]}/${entityId}`,
      {
        params: {
          $filters: $filters ? JSON.stringify($filters) : undefined,
          $limit,
          $offset,
          $order,
        },
        ...config,
      }
    );

  fetchPinLegends = (
    orgId: Organization["id"],
    config?: RequestOptions
  ): Promise<ListResponse<PinLegend>> => this.get(`/organizations/${orgId}/pin-legends`, config);

  createColorPinLegend = (
    orgId: Organization["id"],
    payload: CreateColorPinLegendPayload,
    config?: RequestOptions
  ): Promise<PinLegend> => this.post(`/organizations/${orgId}/pin-legends`, payload, config);

  updateColorPinLegend = (
    orgId: Organization["id"],
    payload: UpdateColorPinLegendPayload,
    config?: RequestOptions
  ): Promise<PinLegend> =>
    this.put(`/organizations/${orgId}/pin-legends/${payload.id}`, payload, config);

  createShapePinLegend = (
    orgId: Organization["id"],
    payload: CreateShapePinLegendPayload,
    config?: RequestOptions
  ): Promise<PinLegend> => this.post(`/organizations/${orgId}/pin-legends`, payload, config);

  updateShapePinLegend = (
    orgId: Organization["id"],
    payload: UpdateShapePinLegendPayload,
    config?: RequestOptions
  ): Promise<PinLegend> =>
    this.put(`/organizations/${orgId}/pin-legends/${payload.id}`, payload, config);

  deletePinLegend = (
    orgId: Organization["id"],
    pinLegendId: PinLegend["id"],
    config?: RequestOptions
  ): Promise<PinLegend> =>
    this.delete(`/organizations/${orgId}/pin-legends/${pinLegendId}`, config);

  addUserRecent = (
    orgId: Organization["id"],
    payload: UserRecentPayload,
    config?: RequestOptions
  ): Promise<UserRecent> => this.post(`/organizations/${orgId}/user-activities`, payload, config);

  fetchUserRecents = (
    orgId: Organization["id"],
    { $filters, $limit, $offset, $order }: Partial<Omit<PlatformListRequest, "$columns">>,
    config?: RequestOptions
  ): Promise<ListResponse<UserRecent>> =>
    this.get(`/organizations/${orgId}/user-activities`, {
      params: {
        $filters: $filters ? JSON.stringify($filters) : undefined,
        $limit,
        $offset,
        $order,
      },
      ...config,
    });

  getLayouts = (
    orgId: Organization["id"],
    { $filters, $limit, $offset, $order }: Partial<Omit<PlatformListRequest, "$columns">>,
    config?: RequestOptions
  ): Promise<ListResponse<FormLayout>> =>
    this.get(`/organizations/${orgId}/layouts`, {
      params: {
        $filters: $filters ? JSON.stringify($filters) : undefined,
        $limit,
        $offset,
        $order,
      },
      ...config,
    });

  getLayout = (
    orgId: Organization["id"],
    id: FormLayout["id"],
    config?: RequestOptions
  ): Promise<FormLayout> => this.get(`/organizations/${orgId}/layouts/${id}`, config);

  getLayoutRoles = (
    orgId: Organization["id"],
    { $limit, $offset }: Partial<Omit<PlatformListRequest, "$columns">>,
    config?: RequestOptions
  ): Promise<ListResponse<FormLayoutRoleAssociation>> =>
    this.get(`/organizations/${orgId}/layout-roles`, {
      params: {
        $limit,
        $offset,
      },
      ...config,
    });

  updateLayoutRoles = (
    orgId: Organization["id"],
    payload: Array<FormLayoutRoleAssociationRecord>,
    config?: RequestOptions
  ): Promise<void> => this.put(`/organizations/${orgId}/layout-roles/bulk`, payload, config);

  createLayout = (
    orgId: Organization["id"],
    payload: FormLayout,
    config?: RequestOptions
  ): Promise<FormLayout> => this.post(`/organizations/${orgId}/layouts`, payload, config);

  updateLayout = (
    orgId: Organization["id"],
    payload: FormLayout,
    config?: RequestOptions
  ): Promise<FormLayout> =>
    this.put(`/organizations/${orgId}/layouts/${payload.id}`, payload, config);

  deleteLayout = (
    orgId: Organization["id"],
    layoutId: FormLayout["id"],
    config?: RequestOptions
  ): Promise<FormLayout> => this.delete(`/organizations/${orgId}/layouts/${layoutId}`, config);

  createChildLayout = (
    orgId: Organization["id"],
    layoutId: FormLayout["id"],
    payload: ChildLayout,
    config?: RequestOptions
  ): Promise<ChildLayout> =>
    this.post(`/organizations/${orgId}/layouts/${layoutId}/child-layout`, payload, config);

  updateChildLayout = (
    orgId: Organization["id"],
    layoutId: FormLayout["id"],
    payload: ChildLayout,
    config?: RequestOptions
  ): Promise<ChildLayout> =>
    this.put(
      `/organizations/${orgId}/layouts/${layoutId}/child-layout/${payload.id}`,
      payload,
      config
    );

  deleteChildLayout = (
    orgId: Organization["id"],
    layoutId: FormLayout["id"],
    childLayoutId: ChildLayout["id"],
    config?: RequestOptions
  ): Promise<ChildLayout> =>
    this.delete(
      `/organizations/${orgId}/layouts/${layoutId}/child-layout/${childLayoutId}`,
      config
    );

  updateFieldAccess = (
    orgId: Organization["id"],
    payload: FieldAccessUpdate,
    config?: RequestOptions
  ): Promise<SchemaField> =>
    this.put(`/organizations/${orgId}/field-access/${payload.id}`, payload, config);

  bulkUpdateFields = (
    orgId: Organization["id"],
    payload: Array<SchemaField>,
    config?: RequestOptions
  ): Promise<SchemaField> => this.put(`/organizations/${orgId}/field-access`, payload, config);

  getSchema = (
    orgId: Organization["id"],
    type: EntityTypesSupportingFieldCustomization,
    config?: RequestOptions
  ): Promise<SchemaField[]> => this.get(`/organizations/${orgId}/${type}/schema`, config);

  getSchemaFieldAccess = (
    orgId: Organization["id"],
    type: EntityTypesSupportingFieldCustomization,
    { $filters, $limit, $offset, $order }: Partial<Omit<PlatformListRequest, "$columns">>,
    config?: RequestOptions
  ): Promise<ListResponse<SchemaField>> =>
    this.get(`/organizations/${orgId}/${type}/schema/field-access`, {
      params: {
        $filters: $filters ? JSON.stringify($filters) : undefined,
        $limit,
        $offset,
        $order,
      },
      ...config,
    });

  fetchDashboards = async (
    orgId: Organization["id"],
    scope?: Scope,
    config?: RequestOptions
  ): Promise<ListResponse<Dashboard>> => {
    const response = await this.get(`/organizations/${orgId}/dashboards`, {
      params: {
        $filters: scope
          ? JSON.stringify({ teamId: scope.teamId ?? null, userId: scope.userId ?? null })
          : undefined,
      },
      ...config,
    });

    response.data = response.data.map(convertFromRawDashboard);

    return response;
  };

  fetchDashboard = async (
    orgId: Organization["id"],
    dashboardId: Dashboard["id"],
    config?: RequestOptions
  ): Promise<Dashboard> => {
    const response = await this.get(`/organizations/${orgId}/dashboards/${dashboardId}`, config);
    return convertFromRawDashboard(response);
  };

  updateDashboard = async (
    orgId: Organization["id"],
    dashboard: Dashboard,
    config?: RequestOptions
  ): Promise<Dashboard> => {
    const response = await this.put(
      `/organizations/${orgId}/dashboards/${dashboard.id}`,
      convertToRawDashboard(dashboard),
      config
    );
    return convertFromRawDashboard(response);
  };

  fetchUserDetailsSso = (sessionId: string, config?: RequestOptions): Promise<UserDetails> =>
    this.get(`/sso/user/${sessionId}`, config);

  updateUserRequestStatus = (
    payload: { status: UserRequestStatus; username: string },
    config?: RequestOptions
  ): Promise<UserDetails> => this.put(`/sso/user`, payload, config);

  fetchDemographicData = (
    { $filters, $limit, $offset }: Partial<PlatformListRequest> = {},
    config?: RequestOptions
  ): Promise<DemographicData[]> =>
    this.get(`/demographic-data`, {
      ...config,
      params: {
        ...config?.params,
        $filters: $filters ? JSON.stringify($filters) : undefined,
        $limit,
        $offset,
      },
    });
}

export type ApiMethodName = keyof ApiService;
export default ApiService;
